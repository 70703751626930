<template>
  <main id="main" class="  vh-80 dark">
    <div class="d-flex justify-content-between m-auto h-dark">
      <div
        @click="$router.push({ path: '/admin' })"
        class="pointer text-white"
        style="padding: 20px"
      >
        <svg
          width="7"
          height="12"
          viewBox="0 0 7 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.68998 11.29C6.78268 11.1975 6.85623 11.0876 6.90641 10.9666C6.95659 10.8456 6.98242 10.716 6.98242 10.585C6.98242 10.454 6.95659 10.3243 6.90641 10.2034C6.85623 10.0824 6.78268 9.97251 6.68998 9.87999L2.80998 5.99999L6.68998 2.11999C6.87696 1.93302 6.982 1.67942 6.982 1.41499C6.982 1.15057 6.87696 0.89697 6.68998 0.709992C6.503 0.523015 6.2494 0.417973 5.98498 0.417973C5.72055 0.417973 5.46696 0.523015 5.27998 0.709992L0.689978 5.29999C0.597275 5.39251 0.523727 5.5024 0.473546 5.62337C0.423364 5.74434 0.397535 5.87402 0.397535 6.00499C0.397535 6.13596 0.423364 6.26564 0.473546 6.38662C0.523727 6.50759 0.597275 6.61748 0.689978 6.70999L5.27998 11.3C5.65998 11.68 6.29998 11.68 6.68998 11.29Z"
            fill="white"
          />
        </svg>
        Back
      </div>

      <div class="mt-3 me-4" v-if="preview">
        <button
          class="btn btn-default bg-white text-dark me-3"
          @click="
            $router.push({
              path: '/create-admin',
              query: { id: $route.query.id },
            })
          "
        >
          Edit
        </button>
        <button
          class="btn btn-default text-white border-white"
          @click="showDialog = true"
        >
          Delete
        </button>
      </div>
    </div>

    <div v-if="showDialog" class="overlay">
      <div class="dialog">
        <h3>Confirm</h3>
        <p>Are you sure you want to delete?</p>
        <div class="buttons">
          <button
            class="btn btn-default manage me-2 px-4"
            size="sm"
            @click="confirm"
          >
            Yes
          </button>
          <button
            class="btn btn-default manage px-4"
            size="sm"
            @click="showDialog = false"
          >
            No
          </button>
        </div>
      </div>
    </div>

    <form
      @submit.prevent="onSubmit"
      class="form-box"
      v-if="form.code || preview == undefined"
    >
      <div class="m-auto text-center  full-h">
        <h5>Create Admin</h5>
        <div class="box-upload p-3 mt-4 py-5" @click="triggerFileInput">
          <a href="#">
            <font-awesome-icon
              v-if="!imagePreview && !form.imagePreview && !$route.query.id"
              icon="fa-solid fa-circle-plus   "
              size="2x"
            />

            <input
              :disabled="preview"
              type="file"
              ref="imageUpload"
              accept="image/*"
              class="hidden"
              @change="previewImage"
            />

            <p
              v-if="!imagePreview && !form.imagePreview && !$route.query.id"
              class="text-sub mb-0"
            >
              Add Picture
            </p>
            <img :src="imagePreview" v-if="imagePreview" style="width: 100%" />
            <img
              :src="form.imagePreview"
              v-if="!imagePreview && form.imagePreview"
              style="width: 100%"
            />
            <img
              v-if="!imagePreview && !form.imagePreview && $route.query.id"
              src="../../assets/img/empty_user.jpeg"
              style="width: 100%"
            />
          </a>
        </div>

        <div class="col-md-8 m-auto mt-5 text-left">
          <div class="mb-3" v-if="$route.query.id">
            <label class="form-label">User ID</label>
            <input
              v-model="form.code"
              disabled
              type="text"
              class="form-control"
            />
          </div>

          <div class="mb-3">
            <label class="form-label">Name</label>
            <input
              :disabled="preview"
              id="name"
              v-model="form.name"
              type="text"
              class="form-control"
              @input="clearFormError('name')"
            />

            <span v-if="formErrors.name" class="text-red">{{
              formErrors.name
            }}.</span>
          </div>

          <div class="mb-3">
            <label class="form-label">Surname</label>
            <input
              :disabled="preview"
              v-model="form.surname"
              type="text"
              class="form-control"
              @input="clearFormError('surname')"
            />
            <span v-if="formErrors.surname" class="text-red">{{
              formErrors.surname
            }}.</span>
          </div>

          <div class="mb-3">
            <label class="form-label">DDI Branch</label>

            <input
              :disabled="preview"
              v-model="form.ddiBranch"
              type="text"
              class="form-control"
              @input="clearFormError('ddiBranch')"
            />
            <span v-if="formErrors.ddiBranch" class="text-red">{{
              formErrors.ddiBranch
            }}.</span>
          </div>

          <div class="mb-3">
            <label class="form-label">Email</label>
            <input
              :disabled="preview || $route.query.id"
              v-model="form.email"

              class="form-control"
              name="username"
              id="username"
              autocomplete="new-username"
              @input="clearFormError('email')"
            />
            <span v-if="formErrors.email" class="text-red">{{
              formErrors.email
            }}.</span>
          </div>

          <div class="mb-3" v-if="!preview">
            <label class="form-label">Password</label>

            <div class="input-group">
              <input
                name="password"
                id="password"
                autocomplete="new-password"
                v-model="form.password"
                class="form-control"
                :type="passwordFieldType"
                @input="clearFormError('password')"
              />
              <button
                type="button"
                class="btn btn-outline-secondary"
                @click="togglePasswordVisibility"
                id="toggle-password"
              >
                <font-awesome-icon
                  :icon="
                    passwordFieldType === 'password'
                      ? 'fa-solid fa-eye'
                      : 'fa-solid fa-eye-slash'
                  "
                />
              </button>
            </div>

            <span v-if="formErrors.password" class="text-red">{{
              formErrors.password
            }}.</span>
          </div>

          <div class="mb-3" v-if="!preview">
            <label class="form-label">Confirm Password</label>

            <div class="input-group">
              <input
                name="confirmPassword"
                id="confirmPassword"
                autocomplete="new-confirmPassword"
                v-model="form.confirmPassword"
                class="form-control"

                :type="passwordFieldType"
                @input="clearFormError('confirmPassword')"
              />

              <!-- :required="!$route.query.id" -->
              <button
                type="button"
                class="btn btn-outline-secondary"
                @click="togglePasswordVisibility"
                id="toggle-password"
              >
                <font-awesome-icon
                  :icon="
                    passwordFieldType === 'password'
                      ? 'fa-solid fa-eye'
                      : 'fa-solid fa-eye-slash'
                  "
                />
              </button>
            </div>
            <span v-if="formErrors.confirmPassword" class="text-red">{{
              formErrors.confirmPassword
            }}.</span>
          </div>

          <!-- <div class="alert alert-danger" v-if="isInvalidPassword">
            Password do not match
          </div> -->

          <div class="mt-5 mb-5">
            <button class="btn btn-primary  w-10" type="submit">
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                v-if="loading"
                aria-hidden="true"
              ></span>
              {{ $t("Save") }}
            </button>
            <button
              class="btn btn-default ms-3 w-10"
              @click="$router.push({ path: '/admin' })"
            >
            {{ $t("Cancel") }}
            </button>
          </div>
        </div>
      </div>
    </form>

    <div class="form-box" v-else style="height: 100vh;">
      <section class="ctnr">
        <div class="ldr">
          <div class="ldr-blk"></div>
          <div class="ldr-blk an_delay"></div>
          <div class="ldr-blk an_delay"></div>
          <div class="ldr-blk"></div>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import { set, reactive, onBeforeMount, getCurrentInstance } from "vue";
import * as Yup from "yup";

import {
  createUser,
  uploadImage,
  getUserById,
  deleteUser,
  updateUser,
} from "@/firebase";
export default {
  name: "Create Admin",

  data() {
    return {
      loading: false,
      passwordFieldType: "password",
      imagePreview: null,
      file: null,
      userInfo: null,
      preview: false,
      showDialog: false,
    };
  },
  async created() {
    this.preview = this.$route.query.view;
  },
  computed: {
    faEye() {
      return faEye;
    },
    faEyeSlash() {
      return faEyeSlash;
    },
  },
  setup() {
    let form = reactive({
      name: "",
      surname: "",
      ddiBranch: "",
      email: "",
      password: "",
      confirmPassword: "",
      createAt: Date.now(),
      role: "admin",
    });

    const instance = getCurrentInstance();

    onBeforeMount(async () => {
      if (instance.proxy.$route.query.id) {
        const userInfo = await getUserById(instance.proxy.$route.query.id);
        // Update form properties with userInfo values
        form.code = userInfo.code;
        form.name = userInfo.name;
        form.surname = userInfo.surname;
        form.ddiBranch = userInfo.ddiBranch;
        form.email = userInfo.email;
        form.imagePreview = userInfo.profileImage;
        form.id = userInfo.id;
        form.docId = userInfo.docId;
      }
    });


    const formErrors = reactive({});



    const validateForm = async (form) => {
      try {
        const schema = Yup.object().shape({
          name: Yup.string().required(),
          surname: Yup.string().required(),
          ddiBranch: Yup.string().required(),
          email: Yup.string()
            .email()
            .required(),
          password: !instance.proxy.$route.query.id || form.password ? Yup.string()
            .min(6) .required(): '',
          confirmPassword: !instance.proxy.$route.query.id || form.password  ? Yup.string()
            .min(6, 'Confirm password must be at least 6 characters')   .required('Confirm password is a required field')
            .oneOf([Yup.ref("password"), null], "Password do not match") : '',
        });

        await schema.validate(form, { abortEarly: false });
        // Form is valid, return true
        return true;
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = {};
          error.inner.forEach((err) => {
            errors[err.path] = err.message;
          });
          Object.assign(formErrors, errors);
        }
        return false;
      }
    };

    function clearFormError(fieldName) {
      formErrors[fieldName] = null;
    }

    return { form, formErrors, validateForm, clearFormError };
  },
  watch: {
    $route(to, from) {
      this.preview = this.$route.query.view;
    },
  },
  methods: {
    async onSubmit() {
      const formIsValid = await this.validateForm(this.form);

      // if(this.form.confirmPassword !== this.form.password) {
      //     this.isInvalidPassword = true
      //     return
      //   }
      if (formIsValid) {
        this.loading = true;

        if (this.file) {
          const imageUrl = await uploadImage(this.file);
          this.form.profileImage = imageUrl;
        }
        if (!this.form.password && this.$route.query.id) {
          delete this.form.password;
        }

        if (this.$route.query.id) {
          this.form.uuid = this.$route.query.id;
          delete this.form.imagePreview;
          console.log(this.form);

          await updateUser({ ...this.form }).then((res) => {
            console.log(res);

            if (res.success) {
              this.messageSuccess("success", "Update Super Admin");
            } else {
              this.messageSuccess("error", res.error.errorInfo.message);
            }
          });
        } else {
          await createUser({ ...this.form }).then((res) => {
            if (res.success) {
              this.messageSuccess("success", "Create Super Admin");
            } else {
              this.messageSuccess("error", res.error.errorInfo.message);
            }
          });
        }
      } else {
        console.log("Form validation failed");
      }
    },
    messageSuccess(status, message) {
      setTimeout(() => {
        this.$router.replace("/admin");

        this.$swal({
          title: message,
          icon: status,
          confirmButtonColor: "#3085d6",
          timer: 10000,
          willClose: () => {
            clearInterval(2000);
          },
        });
      }, 500);
    },
    triggerFileInput() {
      this.$refs.imageUpload.click();
    },
    previewImage(event) {
      const file = event.target.files[0];


      if(file.size>125000) {

        this.$swal({
          title: 'กรุณาอัพโหลดรูปที่มีขนาดน้อยกว่า 1MB',
          icon: 'error',
          confirmButtonColor: "#ff0000",
          timer: 10000,
          willClose: () => {
            clearInterval(2000);
          },
        });

        return
      }
      this.file = file;
      if (!file) return;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imagePreview = reader.result;
      };
    },
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    deleteUser() {
      deleteUser(this.form.id, this.$route.query.id).then(() => {
        this.messageSuccess("Delete");
      });
    },
    confirm() {
      // handle confirm action here
      this.showDialog = false;
      this.deleteUser();
      this.$router.push({ path: "/admin" });
    },
    checkPasswordLength() {
      if (this.form.password.length < 6) {
        alert("Password should be at least 6 characters long.");
      }
    },
  },
};
</script>
<style>
#toggle-password {
  background-color: transparent;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  color: #000;
  height: 100%;
}
.hidden {
  display: none;
}

button.btn-default.manage:hover {
  background-color: #0062cc;
  color: #fff !important;
}
</style>
