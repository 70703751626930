import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/main.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueApexCharts from "vue3-apexcharts";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import VueGoodTablePlugin from 'vue-good-table';

// import the styles
import 'vue-good-table/dist/vue-good-table.css'
import { VueSelect } from "vue-select";


import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars, faUser, faEnvelope, faTrash, faEye, faEyeSlash, faPlusCircle, faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { faCalendar  } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import i18n from './plugins/i18n'
// add the icons to the library
library.add( faBars, faUser, faEnvelope, faCalendar, faTrash, faEye, faEyeSlash, faPlusCircle, faCirclePlus )



const app = createApp(App)
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('v-select', VueSelect)
app.use(router)
app.use(VueApexCharts);
app.use(VueSweetalert2)
app.use(VueGoodTablePlugin);
app.use(i18n)
app.mount('#app')
