<template>
  <div class="main-container">

      <div class="background-image-holder" style="    position: absolute;">
        <img
          alt="background"
          src="../assets/img/bg.jpg"
          class="vh-100 vw-100"
        >
      </div>
      <div class="container pos-vertical-center "  style="
    margin: auto;">
        <div class="row bg-login m-auto">
          <div class="col-md-7 col-lg-12 m-auto d-block">
            <img src="../assets/img/logo.png" class="m-auto d-block" width="300">

            <div class="m-auto d-block text-center" >

           <div class="text-bold"> Welcome to Trivision</div>
           <p>Please Log in to your account</p>
            </div>



            <form  >
              <div class="row">
                <div class="col-md-12">
                  <p class="title-field">Username</p>
                  <input
                  class="form-control  "
                    v-model="email"
                    title="Invalid email"
                    type="email"
                    placeholder="Name@mail.com"

                  >


                </div>
                <div class="col-md-12 mt-4">
                  <p class="title-field">Password</p>
                  <input
                  class="form-control"
                    v-model="password"
                    type="password"
                    placeholder="Password"
                  >
                </div>


                <div class="col-md-12 ">
                <div class="alert alert-danger mb-2 mt-3" v-if="!isValidEmail" style="color: red!important; font-size: 0.8rem;">
                   {{ msg }}
                  </div>
                </div>




                <div class="col-md-12">
                  <button
                    class="btn  w-100 mt-4  btn-primary type--uppercase"
                    type="submit"
                    :disabled="loading"
                    @click="loginWithEmail"
                  >
                  <span class="spinner-border spinner-border-sm" role="status" v-if="loading" aria-hidden="true"></span>

                    Log in
                  </button>

                </div>

                <div class="col-md-12 d-flex  justify-content-between mt-3">


                 <div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="rememberMeCheckbox" v-model="rememberMe">
                    <label class="form-check-label" for="rememberMeCheckbox">
                      Remember me
                    </label>
                  </div>

                 </div>


                 <div>
                <div class="cursor-pointer" @click="$router.push({path: '/forgot-password'})">
                  Forgot password
                </div>

                 </div>

                  </div>

              </div>


              <!--end of row-->
            </form>


          </div>
        </div>
        <!--end of row-->

      </div>
      <!--end of container-->
      <img src="../assets/img/ddi-login.png" width="100" style="z-index: 1000; position:absolute; right: 10px;bottom: 10px;">
  </div>

  <!-- <div id="login" class="main-box">
    <form @click="loginWithEmail">
      <h1>Login</h1>
      <div class="title">
        <h3>Email</h3>
        <input v-model="email" type="text" class="input" placeholder="Email" />
      </div>
      <div class="title">
        <h3>Password</h3>
        <input
          v-model="password"
          type="password"
          class="input"
          placeholder="Password"
        />
      </div>
      <button type="button" value="submit" class="btn">Login</button>
    </form>
  </div> -->
</template>
<script>

import firebase from "firebase";
import { getUserByUid, getTeamConfig } from "@/firebase";
export default {
  name: "LoginPage",
  data: function() {
    return { email: "", password: "", loading: false , rememberMe: false, isValidEmail: true, msg: ''};
  },
  computed: {

  },
  created() {
    localStorage.removeItem('userInfo')

    const username = localStorage.getItem('username')
    const password = localStorage.getItem('password')

  if (username && password) {
    this.email = username
    this.password = password
    this.rememberMe = true
   }
  },
   methods: {
    checkValidEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(this.email);
    },
    loginWithEmail(e) {
      e.preventDefault();
      this.loading = true


      if(!this.checkValidEmail())  {
        this.msg = "Authorization Failed. The specified user name or password is invalid. Please type it again."
        this.isValidEmail = false
        this.loading = false
        return
      }
      if (this.rememberMe) {
          localStorage.setItem('username', this.email)
          localStorage.setItem('password', this.password)
        } else {
          localStorage.removeItem('username')
          localStorage.removeItem('password')
        }
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(
          async (userCredential) => {


            const result = await getUserByUid(userCredential.user.uid)

            if(!result) {
                this.isValidEmail = false
                this.msg = "This user has been deleted"
                this.loading = false
                return
            }
            const teams =  await getTeamConfig()
            localStorage.setItem('userInfo', JSON.stringify(result))

            if(result.role === 'customer') {
             setTimeout(() => {

                this.loading = false
                const teamConfig = teams.map(res => {
                const team = res.teams.find(res2 => {
                  return res2.users.find(res3 => res3.id === result.docId);
                });
                return team ? { idDoc: res.idDoc, data: team } : null;
              }).filter(obj => obj !== null);


              if(teamConfig.length===0) {
                this.isValidEmail = false
                this.msg = "You haven't joined the team yet."

              } else {
                localStorage.setItem('teamConfig', (teamConfig[0].idDoc))
                localStorage.setItem('teamName', (teamConfig[0].data.name))
                 this.$router.replace("/create-data-entry?id="+teamConfig[0].idDoc);
              }
            }, 200);






                // create-data-entry?id=rwjLqs78ncUUyFov0ZdB&view=true


            } else if (result.role === 'user') {
                    this.$router.replace("/user");
            }
            else {
              this.$router.replace("/admin");
            }


          },
          (err) => {
            this.loading = false
            this.isValidEmail = false
            this.msg = err.message
            // alert(err.message);
          }
        );
      e.preventDefault();
    },
    // loginWithProvider(e) {
    //   var provider = new firebase.auth.GoogleAuthProvider();
    //   firebase
    //     .auth()
    //     .signInWithPopup(provider)
    //     .then(
    //       (user) => {
    //         this.$router.replace("/admin");
    //       },
    //       (err) => {
    //         alert(err.message);
    //       }
    //     );
    // },
  },
};
</script>
