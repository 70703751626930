import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import LoginPage from "@/views/LoginPage.vue";


import UserPage from "@/views/UserPage.vue";
import CustomerPage from "@/views/CustomerPage.vue";

import CreateAdmin from "@/views/userAdmin/createAdmin.vue";
import CreateUser from "@/views/userAdmin/createUser.vue";
import CreateCustomer from "@/views/userAdmin/createCustomer.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage
  },
  {
    path: "/forgot-password",
    name: "forgot password",
    component: () => import('@/views/ForgotPassword.vue')
  },
  {
    path: "/reset-password",
    name: "reset password",
    component: () => import('@/views/ResetPassword.vue')
  },

  {
    path: "/admin",
    name: "Admin",
    meta: {
    title: 'User Administration',
    },
    component: () => import('@/views/AdminPage.vue')
  },



  {
    path: "/user",
    name: "User",
    meta: {
      title: 'User Administration',
      },
    component: UserPage
  },
  {
    path: "/customer",
    name: "customer",
    meta: {
      title: 'User Administration',
      },
    component: CustomerPage
  },


  {
    path: "/create-admin",
    name: "create admin",
    meta: {
      title: 'User Administration',
      },
    component: CreateAdmin
  },
  {
    path: "/create-user",
    name: "create user",
    meta: {
      title: 'User Administration',
      },
    component: CreateUser
  },
  {
    path: "/create-customer",
    name: "create customer",
    meta: {
      title: 'User Administration',
      },
    component: CreateCustomer
  },

  {
    path: "/team-configuration",
    name: "Team configuration",
    meta: {
      title: 'Team Configuration',
      },
    component: () => import('@/views/runSession/teamConfig/index.vue')
  },
  {
    path: "/create-session",
    name: "Team create session",
    meta: {
      title: 'Run Session',
      },
    component: () => import('@/views/runSession/teamConfig/createSession.vue')
  },
  {
    path: "/data-entry",
    name: "DataEntry",
    meta: {
      title: 'Data Entry',
      },
    component:  () => import('@/views/runSession/dataEntry/index.vue')
  },
  {
    path: "/create-data-entry",
    name: "CreateDataEntry",
    meta: {
      title: 'Run Session',
      },
    component:  () => import('@/views/runSession/dataEntry/create.vue')
  },

  {
    path: "/utilities",
    name: "utilities",
    meta: {
      title: 'Review Session',
      },
    component:  () => import('@/views/utilities/index.vue')
  },
  {
    path: "/review",
    name: "review",
    meta: {
      title: 'Review Session',
      },
      component:  () => import('@/views/runSession/dataEntry/create.vue')
  },
  {
    path: "/setting-en",
    name: "settingEN",
    meta: {
      title: 'Setting English Year 1',
      },
      component:  () => import('@/views/setting/languageEN.vue')
  },

  {
    path: "/setting-en-2",
    name: "settingEN2",
    meta: {
      title: 'Setting English Year 2',
      },
      component:  () => import('@/views/setting/languageEN2.vue')
  },

  {
    path: "/setting-en-3",
    name: "settingEN3",
    meta: {
      title: 'Setting English Year 3',
      },
      component:  () => import('@/views/setting/languageEN3.vue')
  },



  {
    path: "/setting-th",
    name: "settingTH",
    meta: {
      title: 'Setting Thai',
      },
      component:  () => import('@/views/setting/languageTH.vue')
  },



  {
    path: "/setting-th-2",
    name: "settingTH2",
    meta: {
      title: 'Setting Thai',
      },
      component:  () => import('@/views/setting/languageTH2.vue')
  },


  {
    path: "/setting-th-3",
    name: "settingTH3",
    meta: {
      title: 'Setting Thai',
      },
      component:  () => import('@/views/setting/languageTH3.vue')
  }








];

const router = createRouter({
  history: createWebHistory(),
  mode: "history",
  routes
});

export default router;
