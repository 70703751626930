
<template>
  <div>

  </div>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";
import { filterOrders, filterOrdersMobile } from '@/firebase'
export default {
  name: "HomePage",
  components: {
    VueFinalModal,
  },
  data: () => ({

  }),

  async mounted() {
    window.location.href = '/login'
  },



};
</script>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 650px) {
 ::v-deep .modal-content  {
  top: 10%!important;
  height: auto
  }
}
::v-deep .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
    max-width: 70%;
    margin: auto;
    top: 20%;
    overflow: scroll;
    max-width: 600px;
    overflow-y: auto;
    height: 500px;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
::v-deep .tables {
   position: sticky;
    top: 0;
    width: 100%;
}


        .loaders {
            position: relative;
            width: 108px;
            display: flex;
            justify-content: space-between;
            position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -50px;
                margin-left: -50px;
                width: 100px;
                height: 100px;
                  z-index: 11;

          }
          .loaders::after , .loaders::before  {
            content: '';
            display: inline-block;
            width: 48px;
            height: 48px;
            background-color: #FFF;
            background-image:  radial-gradient(circle 14px, #0d161b 100%, transparent 0);
            background-repeat: no-repeat;
            border-radius: 50%;
            animation: eyeMove 10s infinite , blink 10s infinite;
          }
          @keyframes eyeMove {
            0%  , 10% {     background-position: 0px 0px}
            13%  , 40% {     background-position: -15px 0px}
            43%  , 70% {     background-position: 15px 0px}
            73%  , 90% {     background-position: 0px 15px}
            93%  , 100% {     background-position: 0px 0px}
          }
          @keyframes blink {
            0%  , 10% , 12% , 20%, 22%, 40%, 42% , 60%, 62%,  70%, 72% , 90%, 92%, 98% , 100%
            { height: 48px}
            11% , 21% ,41% , 61% , 71% , 91% , 99%
            { height: 18px}
          }


.full-screen-backgound {
   position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000000;
  z-index: 10;
  opacity: 0.5;
}
</style>

