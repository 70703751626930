<template>
  <Layout v-if="isLogin">
    <div
      class="text-center  "
      style="margin-top: 50px; margin-left: 200px;"
    ></div>
    <router-view />
  </Layout>

  <router-view v-else />
</template>

<script>
import Layout from "@/components/Layout.vue";

export default {
  name: "App",
  components: {
    Layout,
  },
  data() {
    return {};
  },
  computed: {
    isLogin() {
      return !(
        this.$route.path === "/" ||
        this.$route.path === "/reset-password" ||
        this.$route.path === "/login" ||
        this.$route.path === "/forgot-password"
      );
    },
  },
  created() {},
};
</script>
