import firebase from "firebase";
import { ref, onUnmounted } from "vue";
import "firebase/functions";
const config = {
  apiKey: "AIzaSyDyUB1OgqvMQK6LxrNJepybpgKFk9j43QA",
  authDomain: "sle-interface.firebaseapp.com",
  projectId: "sle-interface",
  storageBucket: "sle-interface.appspot.com",
  messagingSenderId: "829733542632",
  appId: "1:829733542632:web:3efe743f97ad99f156875d",
  measurementId: "G-Y9Q03HZS9G",
};

const firebaseApp = firebase.initializeApp(config);
const storage = firebaseApp.storage();
const db = firebaseApp.firestore();
const userCollection = db.collection("users");

const functions = firebase.functions();

export default firebaseApp;
export const createUser = async (user) => {
  const registerUser = functions.httpsCallable("registerUser");
  const response = await registerUser({
    email: user.email,
    password: user.password,
  });

  if (!response.data.success) {
    return response.data;
  } else {
    const queryResult = await userCollection.orderBy('incrementId', 'desc').limit(1).get();
    console.log(queryResult.docs);

    const lastDoc = queryResult.docs[0];
    const lastId = lastDoc.get('incrementId')
    const incrementId = Number(lastId) + 1;
    const typeCode = user.role === 'admin' ? 'AD' : user.role === 'user' ? 'US' : user.role === 'customer' ? 'CU' : null;


    const addZero = incrementId.toString().padStart(5, "0");
    user.code = typeCode + addZero;
    user.incrementId = incrementId

    console.log(response.data);

    user.id = response.data.id;
    delete user.password;

    const result = {}
     await userCollection.add(user);
    result.success = true;
    return result;
  }
};

export const getUsers = async ({ role }) => {
  const querySnapshot = await userCollection.orderBy('incrementId', 'desc')
    .where('role', '==', role)
    .get();


  const users = [];
  await Promise.all(querySnapshot.docs.map(async (doc) => {
    const userData = { idDoc: doc.id, ...doc.data() };
    await new Promise((resolve) => {
      users.push(userData);
      resolve();
    });
  }));

  return users;
};




export const uploadImage = async (event) => {
  const file = event;
  const storageRef = storage.ref();
  const imageRef = storageRef.child(`images/${file.name}`);
  const snapshot = await imageRef.put(file);
  const downloadURL = await snapshot.ref.getDownloadURL();

  return downloadURL;
};

export const getUserByUid = async (id) => {
  const query = await userCollection.where("id", "==", id);
  const querySnapshot = await query.get();

  if (querySnapshot.empty) {
    console.log("No matching documents.");
    return null;
  } else {
    const firstDoc = querySnapshot.docs[0];
    const userData = firstDoc.data();
    const docId = firstDoc.id;
    return { docId: docId, ...userData };
  }
};

export const getUserById = async (id) => {
  try {
    const userDoc = await userCollection.doc(id).get();
    if (userDoc.exists) {
      const userData = userDoc.data();
      const userId = userDoc.id;
      return { ...userData, docId: userId };
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error getting user by ID:', error);
    return null;
  }
};

export const deleteUser = async (id, docId) => {
  try {
    const deleteUser = functions.httpsCallable("deleteUser");
    await deleteUser({ uid: id });
    const res= {}
    await userCollection.doc(docId).delete();
    res.success= true
    return res
  } catch (error) {
    console.error(error);
  }


};

export const updateUser = async (user) => {


  const updateUser = firebase.functions().httpsCallable('updateUser');

    new Promise((resolve, reject) => {
    updateUser({  password: user.password, id: user.id })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.error(error);
        reject(new Error("An error occurred while sending the email."));
      });
  });

  delete user.confirmPassword
  const result = {}
  await userCollection.doc(user.uuid).update(user);
  result.success = true
  return result


};

export const filterUser = async (text) => {
  const result = [];
  // name
  const querySnapshot = await userCollection
    .where("name", ">=", text)
    .where("name", "<=", text + "\uf8ff")
    .get();
  const promises = querySnapshot.docs.map((doc) => {
    return doc.data();
  });
  const name = await Promise.all(promises);
  result.push(...name);

  const querySnapshotSurname = await userCollection
    .where("surname", ">=", text)
    .where("surname", "<=", text + "\uf8ff")
    .get();
  const promisesSurname = querySnapshotSurname.docs.map((doc) => {
    return doc.data();
  });
  const surname = await Promise.all(promisesSurname);
  result.push(...surname);

  const querySnapshotEmail = await userCollection
    .where("email", ">=", text)
    .where("email", "<=", text + "\uf8ff")
    .get();
  const promisesEmail = querySnapshotEmail.docs.map((doc) => {
    return doc.data();
  });
  const email = await Promise.all(promisesEmail);
  result.push(...email);

  const output = Array.from(new Set(result.map((obj) => obj.email))).map(
    (email) => {
      return result.find((obj) => obj.email === email);
    }
  );

  return output;
};



export const sentMailResetPassword = async (email) => {

  const sendEmail = firebase.functions().httpsCallable('generateLinkResetPassword');

  return new Promise((resolve, reject) => {
    sendEmail({ recipient: 'support.ddith@ddiworld.com', emailForReset: email })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        console.error(error);
        reject(new Error("An error occurred while sending the email."));
      });
  });
};


export const resetPassword = async (newPassword, email) => {
  console.log(newPassword);

  console.log(email);

 const sendEmail = firebase.functions().httpsCallable('resetPassword');

sendEmail({   newPassword: newPassword, email: email })
  .then((result) => {
    if (result.data.success) {
      console.log('reset password successfully');


    } else {

    }
  })
  .catch((error) => {
    console.error('Error sending email:', error);
  });

};

export const checkEncryptedTime = async (time) => {
  const checkTime = firebase.functions().httpsCallable('checkEncryptedTime');
  try {
    const result = await checkTime({ encryptedTime: time });


    return result;
  } catch (error) {
    console.error('Error checking encrypted time:', error);
    throw error; // re-throw the error so that the calling code can handle it
  }
 };








////// Team Config ///////

export const getTeamConfig = async () => {

  const querySnapshot = await db.collection("teamConfig").orderBy('incrementId', 'desc')
    .get();


  const users = [];
  await Promise.all(querySnapshot.docs.map(async (doc) => {
    const userData = { idDoc: doc.id, ...doc.data() };
    await new Promise((resolve) => {
      users.push(userData);
      resolve();
    });
  }));

  return users;

}


export const getTeamConfigById = async (id) => {

  try {
    const userDoc = await db.collection("teamConfig").doc(id).get();
    if (userDoc.exists) {
      const userData = userDoc.data();
      const userId = userDoc.id;
      return { ...userData, docId: userId };
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error getting user by ID:', error);
    return null;
  }


}




export const updateTeamConfig = async (data) => {


  try {
    await db.collection("teamConfig").doc(data.uuid).update(data);
    return true
  } catch (error) {
    return null
  }

};







export const deleteTeamConfig = async (docId) => {

  return  db.collection("teamConfig").doc(docId).delete();
};



export const deleteDataEntry = async (docId, data) => {

  return  db.collection("teamConfig").doc(docId).update(data);
};










export const createTeamConfig = async (data) => {


  const queryResult = await db.collection("teamConfig").orderBy('incrementId', 'desc').limit(1).get();

  const lastDoc = queryResult.docs[0];
  const lastId = queryResult.docs.length === 0 ?  0: lastDoc.get('incrementId')
  console.log(lastId);

  const incrementId = Number(lastId) + 1;
  const addZero = incrementId.toString().padStart(5, "0");
  data.code = 'TE' + addZero;
  data.incrementId = incrementId

  console.log('d', data);


    const result = await db.collection("teamConfig").add(data);
    const docId = result.id;
    result.success = true;
    return docId;

};






export const filterTeamConfig = async (text) => {
  const result = [];
  // name
  const querySnapshot = await db.collection("teamConfig")
    .where("name", ">=", text)
    .where("name", "<=", text + "\uf8ff")
    .get();
  const promises = querySnapshot.docs.map((doc) => {
    return doc.data();
  });
  const name = await Promise.all(promises);
  result.push(...name);

  const querySnapshotSurname = await db.collection("teamConfig")
    .where("company", ">=", text)
    .where("company", "<=", text + "\uf8ff")
    .get();
  const promisesSurname = querySnapshotSurname.docs.map((doc) => {
    return doc.data();
  });
  const surname = await Promise.all(promisesSurname);
  result.push(...surname);

  const querySnapshotEmail = await db.collection("teamConfig")
    .where("projectCode", ">=", text)
    .where("projectCode", "<=", text + "\uf8ff")
    .get();
  const promisesEmail = querySnapshotEmail.docs.map((doc) => {
    return doc.data();
  });
  const email = await Promise.all(promisesEmail);
  result.push(...email);

  const output = Array.from(new Set(result.map((obj) => obj.email))).map(
    (email) => {
      return result.find((obj) => obj.email === email);
    }
  );

  return output;
};





export const createDataEntry = async (data, docId) => {

console.log(data);

  try {
     const docRef = await db.collection("teamConfig").doc(docId).set(
      {  teams: data }, {merge: true});
    return true
  } catch (error) {
    return null
  }



}


export const updateDataEntry = async (data, docId) => {

  console.log(data);

    try {
       const docRef = await db.collection("teamConfig").doc(docId).set({
        teams: data
       }, {merge: true});
      return true
    } catch (error) {
      return null
    }


  }








export const filterDataEntry = async (text) => {
  const result = [];
  // name
  const querySnapshot = await db.collection("teamConfig")
    .where("name", ">=", text)
    .where("name", "<=", text + "\uf8ff")
    .get();
  const promises = querySnapshot.docs.map((doc) => {
    return doc.data();
  });
  const name = await Promise.all(promises);
  result.push(...name);

  const querySnapshotSurname = await db.collection("teamConfig")
    .where("company", ">=", text)
    .where("company", "<=", text + "\uf8ff")
    .get();
  const promisesSurname = querySnapshotSurname.docs.map((doc) => {
    return doc.data();
  });
  const surname = await Promise.all(promisesSurname);
  result.push(...surname);

  const querySnapshotEmail = await db.collection("teamConfig")
    .where("projectCode", ">=", text)
    .where("projectCode", "<=", text + "\uf8ff")
    .get();
  const promisesEmail = querySnapshotEmail.docs.map((doc) => {
    return doc.data();
  });
  console.log(promisesEmail);

  const email = await Promise.all(promisesEmail);
  result.push(...email);

  const output = Array.from(new Set(result.map((obj) => obj.email))).map(
    (email) => {
      return result.find((obj) => obj.email === email);
    }
  );

  return output;
};










export const updateSettingEnglishFull = async (data) => {

  console.log('update eng', data);
  
    try {
       const docRef = await db.collection("settingEN").doc("RMZlt9Scb7xRLGvLi8wo").set(
        data, {merge: true});
      return true
    } catch (error) {
      return null
    }
  
  
  }



export const updateSettingEnglishShort = async (data) => {

  console.log('update eng', data);
  
    try {
       const docRef = await db.collection("settingEN").doc("cNhEBU787ZVQJOZZ5i3l").set(
        data, {merge: true});
      return true
    } catch (error) {
      return null
    }
  
  
  }



  export const getSettingEnglishFull = async () => {
    try {
      // Adjust the collection name and document ID as needed
      const docRef = db.collection("settingEN").doc("RMZlt9Scb7xRLGvLi8wo");
      const settingsSnapshot = await docRef.get();
  
      if (settingsSnapshot.exists) {
        const data = settingsSnapshot.data();
  
        // Manual sorting of the keys
        const sortedKeys = [
          'fullQArk1', 'fullQArk2', 'fullQArk3', 
          'fullRaupp1', 'fullRaupp2', 'fullRaupp3', 'fullRaupp4',
          'fullLin1', 'fullLin2', 
          'fullTsao1', 'fullTsao2', 'fullTsao3',
          'fullWeymer1', 'fullWeymer2', 'fullWeymer3', 'fullWeymer4', 'fullWeymer5', 'fullWeymer6', 'fullWeymer7', 'fullWeymer8',
          'fullSlim1', 'fullSlim2', 'fullSlim3',
          'fullSimard1', 'fullSimard2', 'fullSimard3', 'fullSimard4', 'fullSimard5', 'fullSimard6', 'fullSimard7', 'fullSimard8',
          'fullTripoli1', 'fullTripoli2', 'fullTripoli3', 'fullTripoli4', 'fullTripoli5', 'fullTripoli6', 'fullTripoli7', 'fullTripoli8',
          'fullWestby1', 'fullWestby2', 
          'fullBradley1', 'fullBradley2', 'fullBradley3', 
          'fullBarker1', 'fullBarker2', 'fullBarker3', 'fullBarker4',
          'fullEvans1', 'fullEvans2', 'fullEvans3', 
          'fullBradley1_2', 'fullBradley2_2', 'fullBradley3_2', 'fullBradley4_2', 'fullBradley5_2',
          'fullTsao1_2', 'fullTsao2_2',
          'fullMcGuire1', 'fullMcGuire2', 'fullMcGuire3', 'fullMcGuire4', 'fullMcGuire5', 'fullMcGuire6', 'fullMcGuire7', 'fullMcGuire8',
          'fullPatel1', 'fullPatel2', 'fullPatel3',
          'fullAECT1', 'fullAECT2',
          'fullThornton1', 'fullThornton2', 'fullThornton3',
          'fullQuiery1', 'fullQuiery2', 'fullQuiery3', 'fullQuiery4', 'fullQuiery5',
          'fullTeaford1', 'fullTeaford2',
          'fullKessab1', 'fullKessab2', 'fullKessab3',
          'fullCastillo1', 'fullCastillo2', 'fullCastillo3',
          'fullPATaskForce1', 'fullPATaskForce2', 'fullPATaskForce3', 'fullPATaskForce4', 'fullPATaskForce5', 'fullPATaskForce6', 'fullPATaskForce7', 'fullPATaskForce8', 'fullPATaskForce9',
          'fullReliabilityandEducation1', 
          'fullSchatz1', 'fullSchatz2',
          'fullTeaford1_2', 'fullTeaford2_2'
 
        ];
  
        // Create a new object based on the sorted keys
        const sortedData = {};
        sortedKeys.forEach(key => {
          if (data[key]) {
            sortedData[key] = data[key];
          }
        });
        
        return sortedData;
      } else {
        console.log('No such document!');
        return null;
      }
    } catch (error) {
      console.error('Error getting settings:', error);
      return null;
    }
  };


  export const getSettingEnglishShort =async () => {


    try {
      // Adjust the collection name and document ID as needed
      const docRef = db.collection("settingEN").doc("cNhEBU787ZVQJOZZ5i3l");
      const settingsSnapshot = await docRef.get();
  
      if (settingsSnapshot.exists) {
        const data = settingsSnapshot.data();
  
        // Manual sorting of the keys
        const sortedKeys = [
          'shortQArk1', 'shortQArk2', 'shortQArk3', 
          'shortRaupp1', 'shortRaupp2', 'shortRaupp3', 'shortRaupp4',
          'shortLin1', 'shortLin2', 
          'shortTsao1', 'shortTsao2', 'shortTsao3',
          'shortWeymer1', 'shortWeymer2', 'shortWeymer3', 'shortWeymer4', 'shortWeymer5', 'shortWeymer6', 'shortWeymer7', 'shortWeymer8',
          'shortSlim1', 'shortSlim2', 'shortSlim3',
          'shortSimard1', 'shortSimard2', 'shortSimard3', 'shortSimard4', 'shortSimard5', 'shortSimard6', 'shortSimard7', 'shortSimard8',
          'shortTripoli1', 'shortTripoli2', 'shortTripoli3', 'shortTripoli4', 'shortTripoli5', 'shortTripoli6', 'shortTripoli7', 'shortTripoli8',
          'shortWestby1', 'shortWestby2', 
          'shortBradley1', 'shortBradley2', 'shortBradley3', 
          'shortBarker1', 'shortBarker2', 'shortBarker3', 'shortBarker4',
          'shortEvans1', 'shortEvans2', 'shortEvans3', 
          'shortBradley1_2', 'shortBradley2_2', 'shortBradley3_2', 'shortBradley4_2', 'shortBradley5_2',
          'shortTsao1_2', 'shortTsao2_2',
          'shortMcGuire1', 'shortMcGuire2', 'shortMcGuire3', 'shortMcGuire4', 'shortMcGuire5', 'shortMcGuire6', 'shortMcGuire7', 'shortMcGuire8',
          'shortPatel1', 'shortPatel2', 'shortPatel3',
          'shortAECT1', 'shortAECT2',
          'shortThornton1', 'shortThornton2', 'shortThornton3',
          'shortQuiery1', 'shortQuiery2', 'shortQuiery3', 'shortQuiery4', 'shortQuiery5',
          'shortTeaford1', 'shortTeaford2',
          'shortKessab1', 'shortKessab2', 'shortKessab3',
          'shortCastillo1', 'shortCastillo2', 'shortCastillo3',
          'shortPATaskForce1', 'shortPATaskForce2', 'shortPATaskForce3', 'shortPATaskForce4', 'shortPATaskForce5', 'shortPATaskForce6', 'shortPATaskForce7', 'shortPATaskForce8', 'shortPATaskForce9',
          'shortReliabilityandEducation1', 
          'shortSchatz1', 'shortSchatz2',
          'shortTeaford1_2', 'shortTeaford2_2'
        ];
  
        // Create a new object based on the sorted keys
        const sortedData = {};
        sortedKeys.forEach(key => {
          if (data[key]) {
            sortedData[key] = data[key];
          }
        });
        
        return sortedData;
      } else {
        console.log('No such document!');
        return null;
      }
    } catch (error) {
      console.error('Error getting settings:', error);
      return null;
    }

 
  };


 
export const updateSettingEnglishFull2 = async (data) => {

  console.log('update eng', data);
  
    try {
       const docRef = await db.collection("settingEN").doc("BYbDdPgjY8WzMl8Full2").set(
        data, {merge: true});
      return true
    } catch (error) {
      return null
    }
  
  
  }



export const updateSettingEnglishShort2 = async (data) => {

  console.log('update eng', data);
  
    try {
       const docRef = await db.collection("settingEN").doc("BYbDdPgjY8WzMl8Short2").set(
        data, {merge: true});
      return true
    } catch (error) {
      return null
    }
  
  
  }



  export const getSettingEnglishFull2 = async () => {
    try {
      // Adjust the collection name and document ID as needed
      const docRef = db.collection("settingEN").doc("BYbDdPgjY8WzMl8Full2");
      const settingsSnapshot = await docRef.get();
  
      if (settingsSnapshot.exists) {
        const data = settingsSnapshot.data();
  
        // Manual sorting of the keys
        const sortedKeys = [
        
          'fullLin1', 'fullLin2', 'fullLin3', 'fullLin4', 
          'fullPrasad1', 'fullPrasad2', 'fullPrasad3', 'fullPrasad4', 'fullPrasad5',
          'fullRaupp1', 'fullRaupp2', 'fullRaupp3', 
          'fullTsao1', 'fullTsao2', 
          'fullCastillo1', 'fullCastillo2', 
          'fullQArk1', 'fullQArk2', 'fullQArk3',  'fullQArk4', 
          'fullEvans1', 'fullEvans2',
          'fullQuiery1', 'fullQuiery2', 'fullQuiery3',
          'fullTsao1_2',
          'fullKessab1', 'fullKessab2', 'fullKessab3',
          'fullCzoska1', 'fullCzoska2', 'fullCzoska3',
          'fullMcGuire1', 'fullMcGuire2', 'fullMcGuire3', 'fullMcGuire4',
          'fullTsao1_3', 'fullTsao2_3', 'fullTsao3_3', 'fullTsao4_3',
          'fullSchwartz1', 'fullSchwartz2',
          'fullPrasad1_2', 'fullPrasad2_2', 'fullPrasad3_2', 'fullPrasad4_2',
          'fullRR1', 'fullRR2', 'fullRR3', 'fullRR4', 'fullRR5', 'fullRR6', 'fullRR7', 'fullRR8', 'fullRR9', 'fullRR10', 'fullRR11', 'fullRR12', 'fullRR13', 'fullRR14', 'fullRR15', 'fullRR16', 'fullRR17', 'fullRR18', 'fullRR19',
          'fullRR20', 'fullRR21', 'fullRR22', 'fullRR23', 'fullRR24', 'fullRR25', 'fullRR26', 'fullRR27', 'fullRR28', 'fullRR29', 'fullRR30', 'fullRR31', 'fullRR32', 'fullRR33', 'fullRR34', 'fullRR35', 'fullRR36', 'fullRR37', 'fullRR38', 'fullRR39', 'fullRR40', 'fullRR41', 'fullRR42', 'fullRR43', 'fullRR44', 'fullRR45', 'fullRR46', 'fullRR47', 'fullRR48', 'fullRR49', 'fullRR50', 'fullRR51', 'fullRR52', 'fullRR53', 'fullRR54', 'fullRR55', 'fullRR56', 'fullRR57', 'fullRR59', 'fullRR60', 'fullRR61', 'fullRR62', 
          'fullMiddleAsiasalesmanager1', 'fullMiddleAsiasalesmanager2', 'fullMiddleAsiasalesmanager3', 'fullMiddleAsiasalesmanager4',
          'fullTsao1_4', 'fullTsao2_4'

 
        ];
  
        // Create a new object based on the sorted keys
        const sortedData = {};
        sortedKeys.forEach(key => {
          if (data[key]) {
            sortedData[key] = data[key];
          }
        });
        
        return sortedData;
      } else {
        console.log('No such document!');
        return null;
      }
    } catch (error) {
      console.error('Error getting settings:', error);
      return null;
    }
  };


  export const getSettingEnglishShort2 =async () => {


    try {
      // Adjust the collection name and document ID as needed
      const docRef = db.collection("settingEN").doc("BYbDdPgjY8WzMl8Short2");
      const settingsSnapshot = await docRef.get();
  
      if (settingsSnapshot.exists) {
        const data = settingsSnapshot.data();
  
        // Manual sorting of the keys
        const sortedKeys = [
          'shortLin1', 'shortLin2', 'shortLin3', 'shortLin4', 
          'shortPrasad1', 'shortPrasad2', 'shortPrasad3', 'shortPrasad4', 'shortPrasad5',
          'shortRaupp1', 'shortRaupp2', 'shortRaupp3', 
          'shortTsao1', 'shortTsao2', 
          'shortCastillo1', 'shortCastillo2', 
          'shortQArk1', 'shortQArk2', 'shortQArk3',  'shortQArk4', 
          'shortEvans1', 'shortEvans2',
          'shortQuiery1', 'shortQuiery2', 'shortQuiery3',
          'shortTsao1_2',
          'shortKessab1', 'shortKessab2', 'shortKessab3',
          'shortCzoska1', 'shortCzoska2', 'shortCzoska3',
          'shortMcGuire1', 'shortMcGuire2', 'shortMcGuire3', 'shortMcGuire4',
          'shortTsao1_3', 'shortTsao2_3', 'shortTsao3_3', 'shortTsao4_3',
          'shortSchwartz1', 'shortSchwartz2',
          'shortPrasad1_2', 'shortPrasad2_2', 'shortPrasad3_2', 'shortPrasad4_2',
          'shortRR1', 'shortRR2', 'shortRR3', 'shortRR4', 'shortRR5', 'shortRR6', 'shortRR7', 'shortRR8', 'shortRR9', 'shortRR10', 'shortRR11', 'shortRR12', 'shortRR13', 'shortRR14', 'shortRR15', 'shortRR16', 'shortRR17', 'shortRR18', 'shortRR19',
          'shortRR20', 'shortRR21', 'shortRR22', 'shortRR23', 'shortRR24', 'shortRR25', 'shortRR26', 'shortRR27', 'shortRR28', 'shortRR29', 'shortRR30', 'shortRR31', 'shortRR32', 'shortRR33', 'shortRR34', 'shortRR35', 'shortRR36', 'shortRR37', 'shortRR38', 'shortRR39', 'shortRR40', 'shortRR41', 'shortRR42', 'shortRR43', 'shortRR44', 'shortRR45', 'shortRR46', 'shortRR47', 'shortRR48', 'shortRR49', 'shortRR50', 'shortRR51', 'shortRR52', 'shortRR53', 'shortRR54', 'shortRR55', 'shortRR56', 'shortRR57', 'shortRR59', 'shortRR60', 'shortRR61', 'shortRR62', 
          'shortMiddleAsiasalesmanager1', 'shortMiddleAsiasalesmanager2', 'shortMiddleAsiasalesmanager3', 'shortMiddleAsiasalesmanager4',
          'shortTsao1_4', 'shortTsao2_4'
        ];
  
        // Create a new object based on the sorted keys
        const sortedData = {};
        sortedKeys.forEach(key => {
          if (data[key]) {
            sortedData[key] = data[key];
          }
        });
        
        return sortedData;
      } else {
        console.log('No such document!');
        return null;
      }
    } catch (error) {
      console.error('Error getting settings:', error);
      return null;
    }
 
  };

 

  export const updateSettingEnglishFull3 = async (data) => {
    
    try {
      const docRef = await db.collection("settingEN").doc("BYbDdPgjY8WzMl8Full3").set(
        data, {merge: true});
      return true
    } catch (error) {
      return null
    }


  }



  export const updateSettingEnglishShort3 = async (data) => {

    console.log('update eng', data);
    
      try {
        const docRef = await db.collection("settingEN").doc("BYbDdPgjY8WzMl8Short3").set(
          data, {merge: true});
        return true
      } catch (error) {
        return null
      }
    
    
    }



  export const getSettingEnglishFull3 = async () => {
    try {
      // Adjust the collection name and document ID as needed
      const docRef = db.collection("settingEN").doc("BYbDdPgjY8WzMl8Full3");
      const settingsSnapshot = await docRef.get();
  
      if (settingsSnapshot.exists) {
        const data = settingsSnapshot.data();
  
        // Manual sorting of the keys
        const sortedKeys = [
        
          'fullBates1', 'fullBates2', 'fullBates3', 'fullBates4', 'fullBates5', 'fullBates6'
          

 
        ];
  
        // Create a new object based on the sorted keys
        const sortedData = {};
        sortedKeys.forEach(key => {
          if (data[key]) {
            sortedData[key] = data[key];
          }
        });
        
        return sortedData;
      } else {
        console.log('No such document!');
        return null;
      }
    } catch (error) {
      console.error('Error getting settings:', error);
      return null;
    }
  };



  export const getSettingEnglishShort3 = async () => {
    try {
      // Adjust the collection name and document ID as needed
      const docRef = db.collection("settingEN").doc("BYbDdPgjY8WzMl8Short3");
      const settingsSnapshot = await docRef.get();
  
      if (settingsSnapshot.exists) {
        const data = settingsSnapshot.data();
  
        // Manual sorting of the keys
        const sortedKeys = [
        
          'shortBates1', 'shortBates2', 'shortBates3', 'shortBates4', 'shortBates5', 'shortBates6'
          

 
        ];
  
        // Create a new object based on the sorted keys
        const sortedData = {};
        sortedKeys.forEach(key => {
          if (data[key]) {
            sortedData[key] = data[key];
          }
        });
        
        return sortedData;
      } else {
        console.log('No such document!');
        return null;
      }
    } catch (error) {
      console.error('Error getting settings:', error);
      return null;
    }
  };

  

export const updateSettingThai = async (data) => {
  
    try {
       const docRef = await db.collection("settingTH").doc("llOJodzgErucZhRbJvDF").set(
        data, {merge: true});
      return true
    } catch (error) {
      return null
    }
  
  
  }



  ////////////////////////////////////////////////////////////////
  // TH
  ////////////////////////////////////////////////////////////////




export const updateSettingThaiFull = async (data) => {
    try {
       const docRef = await db.collection("settingEN").doc("HCw4WGGyLVpFTRFullTH1").set(
        data, {merge: true});
      return true
    } catch (error) {
      return null
    }
  }



export const updateSettingThaiShort = async (data) => {
    try {
       const docRef = await db.collection("settingEN").doc("HCw4WGGyLVpFTRShortTH1").set(
        data, {merge: true});
      return true
    } catch (error) {
      return null
    }
  }


export const updateSettingThaiFull2 = async (data) => {

  
    
      try {
         const docRef = await db.collection("settingEN").doc("HCw4WGGyLVpFTRFullTH2").set(
          data, {merge: true});
        return true
      } catch (error) {
        return null
      }
    
    
    }
  
  
export const updateSettingThaiShort2 = async (data) => {
      
      try {
         const docRef = await db.collection("settingEN").doc("HCw4WGGyLVpFTRShortTH2").set(
          data, {merge: true});
        return true
      } catch (error) {
        return null
      }
    
    
    }
  

export const updateSettingThaiFull3 = async (data) => {
  
      try {
         const docRef = await db.collection("settingEN").doc("HCw4WGGyLVpFTRFullTH3").set(
          data, {merge: true});
        return true
      } catch (error) {
        return null
      }
    
    
    }
    

    
export const updateSettingThaiShort3 = async (data) => {
      
        try {
           const docRef = await db.collection("settingEN").doc("HCw4WGGyLVpFTRShortTH3").set(
            data, {merge: true});
          return true
        } catch (error) {
          return null
        }
    }
    
    


////////////////////// GET DATA TH //////////////////////

export const getSettingThaiFull = async () => {
  try {
    // Adjust the collection name and document ID as needed
    const docRef = db.collection("settingEN").doc("HCw4WGGyLVpFTRFullTH1");
    const settingsSnapshot = await docRef.get();

    if (settingsSnapshot.exists) {
      const data = settingsSnapshot.data();

      // Manual sorting of the keys
      const sortedKeys = [
        'fullQArk1', 'fullQArk2', 'fullQArk3', 
        'fullRaupp1', 'fullRaupp2', 'fullRaupp3', 'fullRaupp4',
        'fullLin1', 'fullLin2', 
        'fullTsao1', 'fullTsao2', 'fullTsao3',
        'fullWeymer1', 'fullWeymer2', 'fullWeymer3', 'fullWeymer4', 'fullWeymer5', 'fullWeymer6', 'fullWeymer7', 'fullWeymer8',
        'fullSlim1', 'fullSlim2', 'fullSlim3',
        'fullSimard1', 'fullSimard2', 'fullSimard3', 'fullSimard4', 'fullSimard5', 'fullSimard6', 'fullSimard7', 'fullSimard8',
        'fullTripoli1', 'fullTripoli2', 'fullTripoli3', 'fullTripoli4', 'fullTripoli5', 'fullTripoli6', 'fullTripoli7', 'fullTripoli8',
        'fullWestby1', 'fullWestby2', 
        'fullBradley1', 'fullBradley2', 'fullBradley3', 
        'fullBarker1', 'fullBarker2', 'fullBarker3', 'fullBarker4',
        'fullEvans1', 'fullEvans2', 'fullEvans3', 
        'fullBradley1_2', 'fullBradley2_2', 'fullBradley3_2', 'fullBradley4_2', 'fullBradley5_2',
        'fullTsao1_2', 'fullTsao2_2',
        'fullMcGuire1', 'fullMcGuire2', 'fullMcGuire3', 'fullMcGuire4', 'fullMcGuire5', 'fullMcGuire6', 'fullMcGuire7', 'fullMcGuire8',
        'fullPatel1', 'fullPatel2', 'fullPatel3',
        'fullAECT1', 'fullAECT2',
        'fullThornton1', 'fullThornton2', 'fullThornton3',
        'fullQuiery1', 'fullQuiery2', 'fullQuiery3', 'fullQuiery4', 'fullQuiery5',
        'fullTeaford1', 'fullTeaford2',
        'fullKessab1', 'fullKessab2', 'fullKessab3',
        'fullCastillo1', 'fullCastillo2', 'fullCastillo3',
        'fullPATaskForce1', 'fullPATaskForce2', 'fullPATaskForce3', 'fullPATaskForce4', 'fullPATaskForce5', 'fullPATaskForce6', 'fullPATaskForce7', 'fullPATaskForce8', 'fullPATaskForce9',
        'fullReliabilityandEducation1', 
        'fullSchatz1', 'fullSchatz2',
        'fullTeaford1_2', 'fullTeaford2_2'

      ];

      // Create a new object based on the sorted keys
      const sortedData = {};
      sortedKeys.forEach(key => {
        if (data[key]) {
          sortedData[key] = data[key];
        }
      });
      
      return sortedData;
    } else {
      console.log('No such document!');
      return null;
    }
  } catch (error) {
    console.error('Error getting settings:', error);
    return null;
  }
};


export const getSettingThaiShort =async () => {


  try {
    // Adjust the collection name and document ID as needed
    const docRef = db.collection("settingEN").doc("HCw4WGGyLVpFTRShortTH1");
    const settingsSnapshot = await docRef.get();

    if (settingsSnapshot.exists) {
      const data = settingsSnapshot.data();

      // Manual sorting of the keys
      const sortedKeys = [
        'shortQArk1', 'shortQArk2', 'shortQArk3', 
        'shortRaupp1', 'shortRaupp2', 'shortRaupp3', 'shortRaupp4',
        'shortLin1', 'shortLin2', 
        'shortTsao1', 'shortTsao2', 'shortTsao3',
        'shortWeymer1', 'shortWeymer2', 'shortWeymer3', 'shortWeymer4', 'shortWeymer5', 'shortWeymer6', 'shortWeymer7', 'shortWeymer8',
        'shortSlim1', 'shortSlim2', 'shortSlim3',
        'shortSimard1', 'shortSimard2', 'shortSimard3', 'shortSimard4', 'shortSimard5', 'shortSimard6', 'shortSimard7', 'shortSimard8',
        'shortTripoli1', 'shortTripoli2', 'shortTripoli3', 'shortTripoli4', 'shortTripoli5', 'shortTripoli6', 'shortTripoli7', 'shortTripoli8',
        'shortWestby1', 'shortWestby2', 
        'shortBradley1', 'shortBradley2', 'shortBradley3', 
        'shortBarker1', 'shortBarker2', 'shortBarker3', 'shortBarker4',
        'shortEvans1', 'shortEvans2', 'shortEvans3', 
        'shortBradley1_2', 'shortBradley2_2', 'shortBradley3_2', 'shortBradley4_2', 'shortBradley5_2',
        'shortTsao1_2', 'shortTsao2_2',
        'shortMcGuire1', 'shortMcGuire2', 'shortMcGuire3', 'shortMcGuire4', 'shortMcGuire5', 'shortMcGuire6', 'shortMcGuire7', 'shortMcGuire8',
        'shortPatel1', 'shortPatel2', 'shortPatel3',
        'shortAECT1', 'shortAECT2',
        'shortThornton1', 'shortThornton2', 'shortThornton3',
        'shortQuiery1', 'shortQuiery2', 'shortQuiery3', 'shortQuiery4', 'shortQuiery5',
        'shortTeaford1', 'shortTeaford2',
        'shortKessab1', 'shortKessab2', 'shortKessab3',
        'shortCastillo1', 'shortCastillo2', 'shortCastillo3',
        'shortPATaskForce1', 'shortPATaskForce2', 'shortPATaskForce3', 'shortPATaskForce4', 'shortPATaskForce5', 'shortPATaskForce6', 'shortPATaskForce7', 'shortPATaskForce8', 'shortPATaskForce9',
        'shortReliabilityandEducation1', 
        'shortSchatz1', 'shortSchatz2',
        'shortTeaford1_2', 'shortTeaford2_2'
      ];

      // Create a new object based on the sorted keys
      const sortedData = {};
      sortedKeys.forEach(key => {
        if (data[key]) {
          sortedData[key] = data[key];
        }
      });
      
      return sortedData;
    } else {
      console.log('No such document!');
      return null;
    }
  } catch (error) {
    console.error('Error getting settings:', error);
    return null;
  }


};


export const getSettingThaiFull2 = async () => {
  try {
    // Adjust the collection name and document ID as needed
    const docRef = db.collection("settingEN").doc("HCw4WGGyLVpFTRFullTH2");
    const settingsSnapshot = await docRef.get();

    if (settingsSnapshot.exists) {
      const data = settingsSnapshot.data();

      // Manual sorting of the keys
      const sortedKeys = [
      
        'fullLin1', 'fullLin2', 'fullLin3', 'fullLin4', 
        'fullPrasad1', 'fullPrasad2', 'fullPrasad3', 'fullPrasad4', 'fullPrasad5',
        'fullRaupp1', 'fullRaupp2', 'fullRaupp3', 
        'fullTsao1', 'fullTsao2', 
        'fullCastillo1', 'fullCastillo2', 
        'fullQArk1', 'fullQArk2', 'fullQArk3',  'fullQArk4', 
        'fullEvans1', 'fullEvans2',
        'fullQuiery1', 'fullQuiery2', 'fullQuiery3',
        'fullTsao1_2',
        'fullKessab1', 'fullKessab2', 'fullKessab3',
        'fullCzoska1', 'fullCzoska2', 'fullCzoska3',
        'fullMcGuire1', 'fullMcGuire2', 'fullMcGuire3', 'fullMcGuire4',
        'fullTsao1_3', 'fullTsao2_3', 'fullTsao3_3', 'fullTsao4_3',
        'fullSchwartz1', 'fullSchwartz2',
        'fullPrasad1_2', 'fullPrasad2_2', 'fullPrasad3_2', 'fullPrasad4_2',
        'fullRR1', 'fullRR2', 'fullRR3', 'fullRR4', 'fullRR5', 'fullRR6', 'fullRR7', 'fullRR8', 'fullRR9', 'fullRR10', 'fullRR11', 'fullRR12', 'fullRR13', 'fullRR14', 'fullRR15', 'fullRR16', 'fullRR17', 'fullRR18', 'fullRR19',
        'fullRR20', 'fullRR21', 'fullRR22', 'fullRR23', 'fullRR24', 'fullRR25', 'fullRR26', 'fullRR27', 'fullRR28', 'fullRR29', 'fullRR30', 'fullRR31', 'fullRR32', 'fullRR33', 'fullRR34', 'fullRR35', 'fullRR36', 'fullRR37', 'fullRR38', 'fullRR39', 'fullRR40', 'fullRR41', 'fullRR42', 'fullRR43', 'fullRR44', 'fullRR45', 'fullRR46', 'fullRR47', 'fullRR48', 'fullRR49', 'fullRR50', 'fullRR51', 'fullRR52', 'fullRR53', 'fullRR54', 'fullRR55', 'fullRR56', 'fullRR57', 'fullRR59', 'fullRR60', 'fullRR61', 'fullRR62', 
        'fullMiddleAsiasalesmanager1', 'fullMiddleAsiasalesmanager2', 'fullMiddleAsiasalesmanager3', 'fullMiddleAsiasalesmanager4',
        'fullTsao1_4', 'fullTsao2_4'


      ];

      // Create a new object based on the sorted keys
      const sortedData = {};
      sortedKeys.forEach(key => {
        if (data[key]) {
          sortedData[key] = data[key];
        }
      });
      
      return sortedData;
    } else {
      console.log('No such document!');
      return null;
    }
  } catch (error) {
    console.error('Error getting settings:', error);
    return null;
  }
};


export const getSettingThaiShort2 =async () => {


  try {
    // Adjust the collection name and document ID as needed
    const docRef = db.collection("settingEN").doc("HCw4WGGyLVpFTRShortTH2");
    const settingsSnapshot = await docRef.get();

    if (settingsSnapshot.exists) {
      const data = settingsSnapshot.data();

      // Manual sorting of the keys
      const sortedKeys = [
        'shortLin1', 'shortLin2', 'shortLin3', 'shortLin4', 
        'shortPrasad1', 'shortPrasad2', 'shortPrasad3', 'shortPrasad4', 'shortPrasad5',
        'shortRaupp1', 'shortRaupp2', 'shortRaupp3', 
        'shortTsao1', 'shortTsao2', 
        'shortCastillo1', 'shortCastillo2', 
        'shortQArk1', 'shortQArk2', 'shortQArk3',  'shortQArk4', 
        'shortEvans1', 'shortEvans2',
        'shortQuiery1', 'shortQuiery2', 'shortQuiery3',
        'shortTsao1_2',
        'shortKessab1', 'shortKessab2', 'shortKessab3',
        'shortCzoska1', 'shortCzoska2', 'shortCzoska3',
        'shortMcGuire1', 'shortMcGuire2', 'shortMcGuire3', 'shortMcGuire4',
        'shortTsao1_3', 'shortTsao2_3', 'shortTsao3_3', 'shortTsao4_3',
        'shortSchwartz1', 'shortSchwartz2',
        'shortPrasad1_2', 'shortPrasad2_2', 'shortPrasad3_2', 'shortPrasad4_2',
        'shortRR1', 'shortRR2', 'shortRR3', 'shortRR4', 'shortRR5', 'shortRR6', 'shortRR7', 'shortRR8', 'shortRR9', 'shortRR10', 'shortRR11', 'shortRR12', 'shortRR13', 'shortRR14', 'shortRR15', 'shortRR16', 'shortRR17', 'shortRR18', 'shortRR19',
        'shortRR20', 'shortRR21', 'shortRR22', 'shortRR23', 'shortRR24', 'shortRR25', 'shortRR26', 'shortRR27', 'shortRR28', 'shortRR29', 'shortRR30', 'shortRR31', 'shortRR32', 'shortRR33', 'shortRR34', 'shortRR35', 'shortRR36', 'shortRR37', 'shortRR38', 'shortRR39', 'shortRR40', 'shortRR41', 'shortRR42', 'shortRR43', 'shortRR44', 'shortRR45', 'shortRR46', 'shortRR47', 'shortRR48', 'shortRR49', 'shortRR50', 'shortRR51', 'shortRR52', 'shortRR53', 'shortRR54', 'shortRR55', 'shortRR56', 'shortRR57', 'shortRR59', 'shortRR60', 'shortRR61', 'shortRR62', 
        'shortMiddleAsiasalesmanager1', 'shortMiddleAsiasalesmanager2', 'shortMiddleAsiasalesmanager3', 'shortMiddleAsiasalesmanager4',
        'shortTsao1_4', 'shortTsao2_4'
      ];

      // Create a new object based on the sorted keys
      const sortedData = {};
      sortedKeys.forEach(key => {
        if (data[key]) {
          sortedData[key] = data[key];
        }
      });
      
      return sortedData;
    } else {
      console.log('No such document!');
      return null;
    }
  } catch (error) {
    console.error('Error getting settings:', error);
    return null;
  }

};


export const getSettingThaiFull3 = async () => {
  try {
    // Adjust the collection name and document ID as needed
    const docRef = db.collection("settingEN").doc("HCw4WGGyLVpFTRFullTH3");
    const settingsSnapshot = await docRef.get();

    if (settingsSnapshot.exists) {
      const data = settingsSnapshot.data();

      // Manual sorting of the keys
      const sortedKeys = [
      
        'fullBates1', 'fullBates2', 'fullBates3', 'fullBates4', 'fullBates5', 'fullBates6'
        


      ];

      // Create a new object based on the sorted keys
      const sortedData = {};
      sortedKeys.forEach(key => {
        if (data[key]) {
          sortedData[key] = data[key];
        }
      });
      console.log('sortedData TH 3', sortedData)
      return sortedData;
    } else {
      console.log('No such document!');
      return null;
    }
  } catch (error) {
    console.error('Error getting settings:', error);
    return null;
  }
};


export const getSettingThaiShort3 = async () => {
  try {
    // Adjust the collection name and document ID as needed
    const docRef = db.collection("settingEN").doc("HCw4WGGyLVpFTRShortTH3");
    const settingsSnapshot = await docRef.get();

    if (settingsSnapshot.exists) {
      const data = settingsSnapshot.data();

      // Manual sorting of the keys
      const sortedKeys = [
      
        'shortBates1', 'shortBates2', 'shortBates3', 'shortBates4', 'shortBates5', 'shortBates6'
        


      ];

      // Create a new object based on the sorted keys
      const sortedData = {};
      sortedKeys.forEach(key => {
        if (data[key]) {
          sortedData[key] = data[key];
        }
      });
      
      return sortedData;
    } else {
      console.log('No such document!');
      return null;
    }
  } catch (error) {
    console.error('Error getting settings:', error);
    return null;
  }
};
