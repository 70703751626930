import { createI18n } from 'vue-i18n'
import th from '../locales/th.json'
import en from '../locales/en.json'

function loadLocaleMessages() {
  const locales = { en: en, th: th }
  const messages = {}
  Object.keys(locales).forEach(lang => {
    messages[lang] = locales[lang]
  })


  return messages
}

export default createI18n(

  {


  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: loadLocaleMessages()
})