<template>
  <main id="main" class="main vh-80">
    <div
      class="d-flex user-create justify-content-between  bg-white  my-4 p-3 border-box  "
    >
      <div class="row">
        <div class="col-md-6">
          <div class="d-flex m-auto">
            <div class="input-group  ">
              <select class="form-select" @input="onSelectSearch" style="width:250px">
                <option value="none"  > Select Name</option>
                <option
                  v-for="user in onselectUser"
                  :key="user.id"
                  :value="user.name"
                  >{{ user.name }}</option
                >
              </select>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="d-flex">
            <div class="input-group  ">
              <select class="form-select" @input="onSelectSearch" style="width:250px">
                <option value="none" > Select Username</option>
                <option
                  v-for="user in onselectUser"
                  :key="user.id"
                  :value="user.email"
                  >{{ user.email }}</option
                >
              </select>
            </div>
          </div>
        </div>
      </div>


      <span
                class="spinner-border spinner-border-sm m-auto ms-3"
                role="status"
                v-if="loadingSearch"
                aria-hidden="true"
              ></span>


    <button class="btn-user btn btn-danger m-auto  ms-2" v-if="textSearch" @click="clear">
          <div>   Clear</div>
      </button>


      <div class="btn-user">
        <button
          class="btn btn-primary"
          @click="$router.push({ path: '/create-user' })"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.3125 11.25H12.75V7.6875C12.75 7.58437 12.6656 7.5 12.5625 7.5H11.4375C11.3344 7.5 11.25 7.58437 11.25 7.6875V11.25H7.6875C7.58437 11.25 7.5 11.3344 7.5 11.4375V12.5625C7.5 12.6656 7.58437 12.75 7.6875 12.75H11.25V16.3125C11.25 16.4156 11.3344 16.5 11.4375 16.5H12.5625C12.6656 16.5 12.75 16.4156 12.75 16.3125V12.75H16.3125C16.4156 12.75 16.5 12.6656 16.5 12.5625V11.4375C16.5 11.3344 16.4156 11.25 16.3125 11.25Z"
              fill="white"
            />
            <path
              d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z"
              fill="white"
            />
          </svg>
          {{ $t("addUser") }}
        </button>
      </div>
    </div>
    <table
      class="table  table-responsive  bg-white  border-box "
      style="border-radius: 8px;"
      v-if="users.length"
    >
      <thead>
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Name</th>
          <th scope="col">DDI Branch</th>
          <th scope="col">Email / Username</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td style="width:200px">{{ user.code }}</td>
          <td>{{ user.name }} {{ user.surname }}</td>
          <td>{{ user.ddiBranch }}</td>
          <td>{{ user.email }}</td>
          <td>
            <svg
              class="pointer"
              @click="
                $router.push({
                  path: '/create-user',
                  query: { id: user.idDoc, view: 'true' },
                })
              "
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 13C15.2044 13 14.4413 13.3161 13.8787 13.8787C13.3161 14.4413 13 15.2044 13 16C13 16.7956 13.3161 17.5587 13.8787 18.1213C14.4413 18.6839 15.2044 19 16 19C16.7956 19 17.5587 18.6839 18.1213 18.1213C18.6839 17.5587 19 16.7956 19 16C19 15.2044 18.6839 14.4413 18.1213 13.8787C17.5587 13.3161 16.7956 13 16 13ZM16 21C14.6739 21 13.4021 20.4732 12.4645 19.5355C11.5268 18.5979 11 17.3261 11 16C11 14.6739 11.5268 13.4021 12.4645 12.4645C13.4021 11.5268 14.6739 11 16 11C17.3261 11 18.5979 11.5268 19.5355 12.4645C20.4732 13.4021 21 14.6739 21 16C21 17.3261 20.4732 18.5979 19.5355 19.5355C18.5979 20.4732 17.3261 21 16 21ZM16 8.5C11 8.5 6.73 11.61 5 16C6.73 20.39 11 23.5 16 23.5C21 23.5 25.27 20.39 27 16C25.27 11.61 21 8.5 16 8.5Z"
                fill="#484343"
              />
            </svg>
            <svg v-if="userInfo.role === 'admin'"
              class="pointer"
              @click="
                $router.push({
                  path: '/create-user',
                  query: { id: user.idDoc },
                })
              "
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.71 11.04C25.1 10.65 25.1 10 24.71 9.63L22.37 7.29C22 6.9 21.35 6.9 20.96 7.29L19.12 9.12L22.87 12.87L24.71 11.04ZM7 21.25V25H10.75L21.81 13.93L18.06 10.18L7 21.25Z"
                fill="#484343"
              />
            </svg>
            <svg v-if="userInfo.role === 'admin'"
              class="pointer"
              @click="showDialogConfirm(user)"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23 8H19.5L18.5 7H13.5L12.5 8H9V10H23V8ZM10 23C10 23.5304 10.2107 24.0391 10.5858 24.4142C10.9609 24.7893 11.4696 25 12 25H20C20.5304 25 21.0391 24.7893 21.4142 24.4142C21.7893 24.0391 22 23.5304 22 23V11H10V23Z"
                fill="#484343"
              />
            </svg>
          </td>
        </tr>
      </tbody>
    </table>

    <section class="ctnr" v-else-if="!noData">
      <div class="ldr">
        <div class="ldr-blk"></div>
        <div class="ldr-blk an_delay"></div>
        <div class="ldr-blk an_delay"></div>
        <div class="ldr-blk"></div>
      </div>
    </section>

    <div v-else class="text-center">
      Please search by Name or Username.
    </div>

    <pagination
      v-model="currentPage"
      :records="users.length"
      :per-page="itemsPerPage"
      @paginate="onPageChange"
    />

    <div v-if="showDialog" class="overlay">
      <div class="dialog">
        <h3>Confirm</h3>
        <p>Are you sure you want to delete?</p>
        <div class="buttons">
          <button
            class="btn btn-default manage me-2 px-4"
            size="sm"
            @click="confirm"
          >
            Yes
          </button>
          <button
            class="btn btn-default manage px-4"
            size="sm"
            @click="showDialog = false"
          >
            No
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Pagination from "v-pagination-3";
import firebase from "firebase";
import { getUsers, deleteUser, filterUser } from "@/firebase";
import { VueGoodTable } from "vue-good-table";

export default {
  name: "AdminPage",
  components: { VueGoodTable, Pagination },
  data() {
    return {
      users: [],
      showDialog: false,
      deleteId: null,
      textSearch: null,
      loadingSearch: false,
      currentPage: 1,
      itemsPerPage: 10,
      noData: false,
      userInfo: null,
      onselectUser: []
    };
  },
  setup() {},

  computed: {
    totalPages() {
      return Math.ceil(this.users.length / this.itemsPerPage);
    },
    // Compute the start and end indexes for the current page.
    startIndex() {
      return (this.currentPage - 1) * this.itemsPerPage;
    },
    endIndex() {
      return this.startIndex + this.itemsPerPage;
    },
    // Compute the items to display on the current page.
    displayedItems() {
      return this.users.slice(this.startIndex, this.endIndex);
    },
  },
  async created () {
     const userId = localStorage.getItem("userInfo");

    if (userId) {
      const info = JSON.parse(userId);
      this.userInfo = info;
    }


    this.initialUser();
  },

  mounted() {
    this.onPageChange(1);
  },
  beforeCreate() {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        this.$router.replace("/");
        //  alert("You don't have a permission");
      }
    });
  },
  methods: {
    clear() {
      this.textSearch = "";
      this.initialUser();
    },
    onPageChange(page) {
      this.currentPage = page;
    },

    async initialUser() {
      const result = await getUsers({ role: "user" });
      this.users = result;
      this.onselectUser = result
    },

    showDialogConfirm(user) {
      console.log(user.id);

      this.deleteId = {
        id: user.id,
        uuid: user.idDoc,
      };
      console.log(this.deleteId);

      this.showDialog = true;
    },
    confirm() {
      // handle confirm action here
      this.showDialog = false;
      this.deleteUser();
    },
    deleteUser() {
      deleteUser(this.deleteId.id, this.deleteId.uuid).then(() => {
        this.messageSuccess("Delete");
        this.initialUser();
      });
    },

    messageSuccess(message) {
      this.$swal({
        title: message + " DDI Admin",
        text: "Super Admin " + message + "ed",
        icon: "success",
        confirmButtonColor: "#3085d6",
        timer: 1500,
        willClose: () => {
          clearInterval(1500);
        },
      });
    },

    async onSelectSearch(val) {
      this.textSearch = ''
      this.loadingSearch = true;
      const result = await filterUser(val.target.value);
      this.users = result;
      this.noData = result.length === 0;
      this.loadingSearch = false;
      this.textSearch = val.target.value
      if (!val.target.value) {
        this.initialUser();
      }
    },

    async searchUser() {
      this.loadingSearch = true;
      const result = await filterUser(this.textSearch);
      this.users = result;
      this.noData = result.length === 0;
      this.loadingSearch = false;

      if (!this.textSearch) {
        this.initialUser();
      }
    },
  },
};
</script>

<style>
.table-responsive {
  width: 100%;
  display: inline-table !important;
}

td {
  padding: 1em;
}

.table th {
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: #c1c1c1;
}

/* .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #eee;
}
table tr:not(:last-of-type) {
    border-bottom: 1px solid #ffffff;
}  */
</style>
